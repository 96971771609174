import BasePlugin from '../BasePlugin'

export default class LoadFileOverall extends BasePlugin {
  async execute () {
    this.context.$loading()
    let me = this
    let address = `${this.context.$config.api}/files/46f9e51f-f705-4d4d-b62b-3ba0f6623e3b.pptx`
    this.context.$http({
      method: 'get',
      url: address,
      responseType: 'arraybuffer'
    }).then((response2) => {
      this.openExportAttachment(response2.data, me, 'pptx', 'Анализ исполнения_общий.pptx')
      this.context.$loading().close()
    })
  }
}
